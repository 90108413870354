export const translations = {
    en: {
      //user settings
      userSettings: 'User Settings',
      editProfile: 'Edit Profile',
      downloadData: 'Download Data',
      deleteAccount: 'Delete Account',
      //register login with google
      loginWithGoogle: "Sign in with Google",
      registerWithGoogle: "Register with Google",
      //stripe credit purchase
      stripeNotLoaded: "Stripe has not loaded yet. Please try again.",
      paymentProcessingError: "An error occurred while processing your payment. Please try again.",
      enterCardDetails: "Enter your card details:",
      stripeLoadError: "Error loading Stripe",
      loadingStripe: "Loading Stripe...",
      paymentSuccessful: "Payment Successful!",
      creditsAdded: "Your credits have been added to your account.",
      backToPurchase: "Back to Purchase Credits",
      backToDashboard: "Back to the Home Page",
      paymentCancelled: "Payment Cancelled",
      tryAgainLater: "Your payment was cancelled. Please try again later.",
      //contact page
      contactUs: "Contact Us",
      contactUsSubtitle: "We're here to help. Reach out to us through the following methods.",
      email: "Email",
      phone: "Phone",
      address: "Address",
      staticContactMessage: "If you have any questions or need assistance, please feel free to contact us using the details below or through our email and phone links.",
      sendEmail: "Send Email",
      callUs: "Call Us",
      messageSent: "Message sent successfully!",
    
      
      getStartedFree: "Get Started for Free",
      whyRegister: "Why Register?",
      registerBenefit1: "Free access to basic blood test interpretation",
      registerBenefit2: "Secure storage of your health data",
      registerBenefit3: "Track your health trends over time",
      registerBenefit4: "Access to AI-powered health insights",
      userTestimonials: "What Our Users Say",
      testimonial1: "This app has made understanding my blood tests so much easier. It's like having a personal health assistant!",
      testimonial2: "I love how I can track my health progress over time. It's motivating to see the improvements.",
      testimonial3: "The explanations are clear and easy to understand. It's helped me have better conversations with my doctor.",
      callToActionDescription: "Join thousands of users who are taking control of their health. Sign up now and start understanding your blood tests like never before!",
      signUpFree: "Sign Up Free",
      freeCreditsOffer: "Sign up now and get 20 credits for free!",
      freeCredits: "Free Credits",
      freeCreditsDesc: "Start with 20 free credits to explore all features of our platform.",
      registerBenefit5: "Get 20 free credits to start interpreting your blood tests immediately.",
      //homepage 
      keyFeatures: "Key Features",
      accurateInterpretation: "Accurate Interpretation",
      accurateInterpretationDesc: "Get reliable interpretations based on the latest medical guidelines.",
      userFriendly: "User-Friendly",
      userFriendlyDesc: "Simple and intuitive interface for easy navigation and data input.",
      trendAnalysis: "Trend Analysis",
      trendAnalysisDesc: "Track changes in your blood test results over time.",
      dataSecurity: "Data Security",
      dataSecurityDesc: "Your health data is encrypted and securely stored.",
      howItWorks: "How It Works",
      creditSystem: "Credit System",
      howCreditsWork: "How Credits Work",
      buyCredits: "Buy Credits",
      credits: "Credits",
      buyNow: "Buy Now",
      readyToStart: "Ready to Start Interpreting Your Blood Tests?",
      signUp: "Sign Up",
      improvedHomeDescription: "Med Web Reports helps you understand your lab results, track your health over time, and gain valuable insights. Our AI-powered tool provides easy-to-understand explanations and personalized health information.",
      medicalGuidance: "Medical Guidance",
      medicalGuidanceDesc: "Receive tailored health insights and suggestions based on your blood test results.",
      improvedStep1: "Input your blood test results manually or upload a photo of your lab report.",
      improvedStep2: "Our AI analyzes your results and provides an easy-to-understand interpretation.",
      improvedStep3: "Get personalized health insights and trend analysis based on your historical data.",
      improvedStep4: "Use the AI chat feature to ask questions about your results and receive guidance.",
      improvedStep5: "Generate comprehensive reports for your personal records or to share with your healthcare provider.",
      disclaimerTitle: "Disclaimer",
      disclaimerContent: "Med Web Reports is an educational tool designed to help you understand your lab results and its connection with your patient history and with the AI aid. It does not provide medical diagnoses and should not be used as a substitute for professional medical advice, diagnosis, or treatment. Always consult with a qualified healthcare provider for medical guidance.",
      creditSystemDesc: "Use credits to perform various actions in the app",
      whatYouCanDo: "What you can do with credits:",
      creditUsage3: "4 credits: Add, update, or delete blood tests, patient history, and body measurements",
      creditUsage2: "5 credits: Download reports and use the HealthInsight AI",
      creditUsage4: "1.5 credits: Chat with the AI about your report",
      creditUsage1: "5 credits: Use OCR to automatically upload test results from a photo",
      improvedCreditsExplanation: "Our credit system allows you to perform various actions in the app. Here's how you can use your credits:",
      creditPackages: "Credit Packages",
      improvedStartNowDescription: "Sign up now to start interpreting your blood tests, tracking your health, and gaining valuable insights. Your journey to better health understanding begins here!",

      //credits
      tier: "Tier",
      unlimitedCredits: "Unlimited Credits",
      credits: "Credits",
      buyCredits: "Buy Credits",
      notEnoughCredits: "Not enough credits",
      savedSuccessfully: "Saved successfully",
      errorSaving: "Error saving data",
      reportDownloaded: "Report downloaded successfully",
      errorDownloading: "Error downloading report",
      purchaseCredits: "Purchase Credits",
      buy: "Buy",
      creditsFor: "credits for",
      tierUpgraded: "Your tier has been upgraded to",

      //report patient history
      patientHistoryReport: "Patient History Report",
      bodyMeasurements: "Body Measurements",
      noBodyMeasurementsAvailable: "No body measurements available",
      noPatientHistoryAvailable: "No patient history available",
      examNotes: "Exam Notes",
      generatePatientHistoryReport: "Generate Patient History Report",
      reportGenerated: "Patient History Report generated successfully",
      //Report for Blood History
      bloodTestType: "Blood Test Type",
      values: "Values",
      editReport: "Edit Report",
      downloadReport: "Download Report",
      editReportDetails: "Edit Report Details",
      fullName: "Full Name",
      dateOfBirth: "Date of Birth",
      gender: "Gender",
      hospitalName: "Hospital Name",
      saveDetails: "Save Details",
      pleaseEditReportFirst: "Please edit the report details before downloading.",
      reportPreview: "Report Preview",
      consentToDownload: "I consent to download this report and understand that it contains my personal health information.",
      pleaseProvideConsent: "Please provide consent to download the report.",
      department: "Department",
      examType: "Exam Type",
      examDate: "Exam Date",
      bloodTestReport: "Blood Test Report",
      departmentOfLaboratoryMedicine: "Department of Laboratory Medicine",
      patientName: "Patient Name",
      hospital: "Hospital",
      notAvailable: "N/A",
      bloodTestResults: "Blood Test Results",
      test: "Test",
      result: "Result",
      referenceRange: "Reference Range",
      signature: "Signature",
      noTestResults: "No test results available",
      //footer
      privacyPolicy: "Privacy Policy",
      termsOfService: "Terms of Service",
      contact: "Contact",

      //patient history other data and templates
      date: "Date",
      entryType: "Entry Type",
      bodyMeasurement: "Body Measurement",
      fullInformation: "Full Information",
      weight: "Weight",
      height: "Height",
      bmi: "BMI",
      bloodPressure: "Blood Pressure",
      pulse: "Pulse",
      newAllergies: "New Allergies",
      therapy: "Therapy",
      newIntolerances: "New Intolerances",
      assessmentAndPlan: "Assessment and Plan",
      followUp: "Follow Up",
      updateConsentLabel: "I consent to update this information",
      deleteConsentLabel: "I consent to delete this entry",
      weightPlaceholder: "Enter weight in kg",
      heightPlaceholder: "Enter height in cm",
      bmiPlaceholder: "Enter BMI",
      bloodPressurePlaceholder: "Enter blood pressure (e.g., 120/80)",
      pulsePlaceholder: "Enter pulse rate",
      noDataAvailable: "No data available for analysis",
      fetchDataError: "Error fetching data. Please try again.",
      analyzeDataError: "Error analyzing data. Please try again.",
     
    
      
      //medical template
      newTranslationKey: "New English text",
      medicalTemplateTitle: "Medical History Template",
      visitOutcomeNote: "Visit Outcome Note",
      historyOfPresentIllness: "History of Present Illness",
      pastMedicalHistory: "Past Medical History",
      medications: "Medications",
      allergies: "Allergies",
      familyHistory: "Family History",
      socialHistory: "Social History",
      reviewOfSystems: "Review of Systems",
      physicalExamination: "Physical Examination",
      assessment: "Assessment",
      plan: "Plan",
      // General
      welcome: "Welcome to Med Web Reports",
      startInterpreting: "Start Interpreting",
      home: "Home",
      bloodTestInput: "Blood Test Input",
      userDashboard: "User Dashboard",
      patientHistory: "Patient History",
      bloodTestHistory: "Blood Test History",
  
      // Home
      homeDescription: "Understand your blood test results with ease and accuracy.",
  
      // TestInput
      inputResults: "Input Blood Test Results",
      editResults: "Edit Blood Test Results",
      testDate: "Test Date",
      searchTests: "Search Tests",
      testName: "Test Name",
      value: "Value",
      unit: "Unit",
      loadMore: "Load More",
      imageUploadOcr: "Image Upload and OCR",
      performOCR: "Perform OCR",
      processingImage: "Processing image...",
      ocrCompleted: "OCR completed successfully. Please verify the extracted values.",
      consent: "I consent to the storage and processing of my blood test data in accordance with the privacy policy.",
      saveBloodTest: "Save Blood Test",
      updateBloodTest: "Update Blood Test",
      interpretResults: "Interpret Results",
      enterValidDate: "Please enter a valid date for the blood test.",
      enterAtLeastOne: "Please enter at least one valid test result.",
      invalidValue: "Invalid value for {test}. Please enter a valid number.",
      dataSaved: "Blood test data saved successfully.",
      addCustomTest: "Add Custom Test",
      editCustomTest: "Edit Custom Test",
      deleteCustomTest: "Delete Custom Test",
      testName: "Test Name",
      value: "Value",
      unit: "Unit",
      add: "Add",
      update: "Update",
      cancel: "Cancel",
      customTestUpdated: "Custom test updated successfully",
      customTestDeleted: "Custom test deleted successfully",
      confirmDeleteCustomTest: "Are you sure you want to delete this custom test?",
      
      
  
      // ImageUpload
      uploadTestResultImage: "Upload Test Result Image",
      uploadImage: "Upload Image",
      uploadValidImage: "Please upload a valid image file.",
  
      // Results
      bloodTestResultsInterpretation: "Blood Test Results Interpretation",
      enterNewResults: "Enter New Results",
      printResults: "Print Results",
      summary: "Summary",
      summaryNote: "Note: This summary is based only on the test results you provided and should not be considered as comprehensive medical advice. Always consult with a healthcare professional for proper interpretation of your test results and medical guidance.",
  
      // Authentication
      login: "Login",
      register: "Register",
      email: "Email Address",
      password: "Password",
      confirmPassword: "Confirm Password",
      gdprConsent: "I consent to the processing of my personal data in accordance with GDPR",
      logout: "Logout",
      pleaseLogin: "Please log in to access blood test history.",
      loginFailed: "Failed to log in. Please check your credentials.",
      registrationSuccessful: "Registration successful! Redirecting to login...",
      registrationFailed: "Failed to register. ",
      passwordsMismatch: "Passwords do not match.",
  
      // PatientDataOverview
      patientDataOverview: "Patient Data Overview",
      viewBloodTestHistory: "View Blood Test History",
      viewFullPatientHistory: "View Full Patient History",
  
      // PatientHistory

      addNewEntry: "Add Patient History",
      editPatientHistory: "Edit Patient History",
      information: "Information",
      actions: "Actions",
      update: "Update",
      cancel: "Cancel",
      delete: "Delete",
      confirmDeletion: "Confirm Deletion",
      deleteConfirmation: "Are you sure you want to delete this history entry? This action cannot be undone.",
      noHistoryFound: "No history entries found.",
  
      // Errors and Notifications
      error: "Error",
      success: "Success",
      pleaseConsent: "Please provide consent to store and process your data.",
      mustBeLoggedIn: "You must be logged in to save blood test data.",
      errorProcessingImage: "Error processing image. Please try again or enter values manually.",
      errorFetchingHistory: "Failed to fetch patient history. Please try again later.",
      errorSavingData: "Failed to save blood test: {error}",
      updateSuccess: "Patient history updated successfully.",
      updateError: "Failed to update patient history. Please try again.",
      save: "Save",
  
      // HealthcareDisclaimer
      disclaimer: "Disclaimer: This app is for informational purposes only and does not provide medical advice. The results and interpretations provided should not be considered as a substitute for professional medical advice, diagnosis, or treatment. Always seek the advice of your physician or other qualified health provider with any questions you may have regarding a medical condition.",
  
      // DataRetentionControls
      dataControl: "Data Control",
      deleteDataRight: "You have the right to request deletion of your data at any time.",
      deleteMyData: "Delete My Data",
  
      // GDPRConsent
      consentForDataProcessing: "Consent for Data Processing",
      gdprExplanation: "We collect and process your health data to provide blood test interpretation services. Your data is encrypted and stored securely. You have the right to access, rectify, or delete your data at any time. Do you consent to this data processing?",
      decline: "Decline",
      iConsent: "I Consent",

      //BloodTestHistory
      noBloodTestsFound: "No blood tests found. Please add some tests.",
      searchTests: "Search Tests",
      editBloodTest: "Edit Blood Test",
      editBloodTestInstructions: "Please edit the details of the blood test below.",
      deleteBloodTestConfirmation: "Are you sure you want to delete this blood test? This action cannot be undone.",
      deleteBloodTestConsent: "I consent to delete this blood test entry.",
      updateBloodTestConsent: "I consent to the update and processing of my blood test data in accordance with the privacy policy.",

      // Health Insights (New)
      healthInsights: "Health Insights",
      analyzeHealth: "Analyze My Health",
      healthAnalysis: "Health Analysis",
      chatWithAI: "Chat with the AI Report",
      chatCreditCost: "Each chat interaction costs {credits} credits",
      notEnoughCreditsChat: "Not enough credits for chat. Each interaction costs 1.5 credits.",
      chatCreditsDeducted: "{credits} credits deducted for chat interaction.",
      chatError: "An error occurred during the chat. Please try again.",



      you: "You",
      askAboutHealth: "Ask about your health...",
      send: "Send",
      healthInsightsReport: "Health Insights Report",
      analysisDate: "Analysis Date",
      printReport: "Print Report",
      noAnalysisToReport: "No analysis available to generate a report. Please analyze your health first.",
      user: "User",

      ai: "AI",
      typeYourQuestion: "Type your question here...",

      chatHistory: "Chat History",
      consentForm: "Consent Form",
      consentFormText: "I hereby confirm that I have read and understood the health insights provided in this report. I acknowledge that this report is for informational purposes only and does not constitute medical advice. I agree to consult with a qualified healthcare professional before making any decisions based on this information.",
      patientSignature: "Patient Signature",

      generateReport: "Generate Report",
      patientDetails: "Patient Details",
      next: "Next",
      back: "Back",
      consentRequired: "Consent Required",
      consentText: "By generating this report, you acknowledge that the information provided is for informational purposes only and does not constitute medical advice. Please consult with a healthcare professional for proper interpretation and medical guidance.",
      consentCheckboxLabel: "I have read and agree to the terms above",
      pleaseProvideDetails: "Please provide the following details to generate your report:",
      noDataForQuestions: "No patient data available to generate questions. Please ensure patient details and blood tests are available.",
      errorGeneratingQuestions: "Error generating suggested questions. Please try again.",
      errorFetchingData: "Error fetching patient data. Please try again.",
      importantQuestionsForDoctor: "Adviced Questions to Ask Your Doctor",

      invalidFileType: "Invalid file type. Please upload a .png, .jpeg, .jpg, or .webp file.",
      fileTooLarge: "File is too large. Please upload an image smaller than 2MB.",
      uploadImage: "Upload Image",

      compareFeatures: "Compare Features",
      freeVersion: "Free Version",
      premiumVersion: "Premium Version",
      ocrFeature: "OCR Technology",
      ocrDescription: "Automatically upload fields with photos",
      credits: "Credits",
      creditsDescription: "Credits for using app features",
      moreCreditsTiers: "More credits based on tier",
      addModifyDelete: "Add, Modify, Delete Data",
      addModifyDeleteDescription: "Manage blood tests, patient history, and body measurements",
      aiInsights: "AI-Powered Insights",
      aiInsightsDescription: "Get AI-generated health insights",
      //comparison premium
      compareFeaturesComp: "Compare Features",
      freeVersionComp: "Free Version",
      premiumVersionComp: "Premium Version",
      ocrFeatureComp: "OCR Technology",
      ocrDescriptionComp: "Automatically upload fields with photos",
      creditsComp: "Credits",
      creditsDescriptionComp: "Credits for using app features",
      moreCreditsTiersComp: "More credits based on tier",
      addModifyDeleteComp: "Add, Modify, Delete Data",
      addModifyDeleteDescriptionComp: "Manage blood tests, patient history, and body measurements",
      aiInsightsComp: "AI-Powered Insights",
      aiInsightsDescriptionComp: "Get AI-generated health insights",

      //validation login
      tooManyAttempts: "Too many failed attempts. Please try again later.",
      accountLocked: "Your account is temporarily locked. Please try again in 15 minutes.",
      weakPassword: "Password is too weak. It should be at least 8 characters long and include uppercase, lowercase, numbers, and special characters.",
      passwordRequirements: "Password must be at least 8 characters long and include uppercase, lowercase, numbers, and special characters.",
      invalidEmail: "Please enter a valid email address.",

      //faq
      faqTitle: "Frequently Asked Questions",
      faqBloodTestQuestion: "How do I add my blood test results?",
      faqBloodTestStep1: "Click on 'Blood Test Input' in the navigation panel.",
      faqBloodTestStep2: "Insert the mandatory fields: Test Date and at least 1 blood test value and Unit.",
      faqBloodTestStep3: "Click the consent checkbox to agree to storage and processing of data according to the privacy policy.",
      faqBloodTestStep4: "Click 'Save Blood Test' to save the data and store it.",
      faqPatientHistoryQuestion: "How do I add my Patient History?",
      faqPatientHistoryStep1: "Click on 'Patient History' in the navigation menu.",
      faqPatientHistoryStep2: "Click 'Add Patient History' to begin inserting your data.",
      faqPatientHistoryStep3: "Choose the correct section based on what patient data you want to insert: Patient History or Body Measurement.",
      faqPatientHistoryStep4: "Fill in the appropriate fields for your chosen section.",
      faqPatientHistoryStep5: "Provide consent in the checkbox and click 'Save' to store the data.",
      faqAIAnalysisQuestion: "How do I perform AI analysis on my data?",
      faqAIAnalysisStep1: "Click on 'User Dashboard'.",
      faqAIAnalysisStep2: "Click on 'Analyze my Health'.",
      faqAIAnalysisStep3: "Verify the correctness of your data and scroll down.",
      faqAIAnalysisStep4: "Click 'Analyze My Health' to start the AI analysis.",
      faqLanguageQuestion: "How do I switch between English and Italian?",
      faqLanguageAnswer: "On the navigation bar, click 'IT' to switch to Italian and 'EN' to switch to English.",
      faqBuyCreditsQuestion: "How do I buy credits?",
      faqBuyCreditsStep1: "On the navigation bar, click 'Buy Credits'.",
      faqBuyCreditsStep2: "Choose the tier you prefer and click 'Buy', then follow the Stripe payment process.",
      faqBuyCreditsStep3: "Click 'Contact Us' if you wish for a different arrangement.",

      analyzing: "analyzing",
      addBloodTest: "Add Blood Test",
      invalidBloodTestData: "Invalid blood test data.",

      userNotLoggedIn: 'User is not logged in.',
      incorrectPassword: 'The current password is incorrect.',
      reauthenticatePrompt: 'Please enter your current password to update your profile.',
      errorUpdatingProfile: 'Error updating profile.',
      currentPassword: 'Current Password',
      newPassword: 'New Password',
      enterCurrentPassword: 'Enter your current password to change email or password.',
      leaveBlankToKeepSame: 'Leave blank to keep the same password.',
      saveChanges: 'Save Changes',
      firstName: "First Name",
      lastName:"Last Name",
      premiumExplanation: 'Premium is currently considered any user which bought at least once credits. This might change in future.',
      infoResults: "to get the AI interpretation make sure to go to the User Dashboard/ Health Insights page"
    },
    it: {
      //user account
      userSettings: 'Impostazioni utente',
      editProfile: 'Modifica Profilo',
      downloadData: 'Scarica dati',
      deleteAccount: 'Elimina Account',
      //register/login with google
      registerWithGoogle: "Registrati con Google",
      loginWithGoogle: "Accedi con Google",
      //stripe credit purchase
      stripeNotLoaded: "Stripe non è ancora caricato. Per favore riprova.",
      paymentProcessingError: "Si è verificato un errore durante l'elaborazione del pagamento. Per favore riprova.",
      enterCardDetails: "Inserisci i dettagli della tua carta:",
      stripeLoadError: "Errore durante il caricamento di Stripe",
      loadingStripe: "Caricamento di Stripe in corso...",
      paymentSuccessful: "Pagamento riuscito!",
      creditsAdded: "I tuoi crediti sono stati aggiunti al tuo account.",
      backToPurchase: "Torna all'acquisto di crediti",
      backToDashboard: "Torna nella Home Page",
      paymentCancelled: "Pagamento annullato",
      tryAgainLater: "Il tuo pagamento è stato annullato. Per favore riprova più tardi.",
      //contact page
      contactUs: "Contattaci",
      contactUsSubtitle: "Siamo qui per aiutarti. Contattaci con i seguenti metodi.",
      email: "Email",
      phone: "Telefono",
      address: "Indirizzo",
      staticContactMessage: "Se hai domande o necessiti di assistenza, non esitare a contattarci usando i dettagli qui sotto o tramite i link per email e telefono.",
      sendEmail: "Invia Email",
      callUs: "Chiamaci",
      messageSent: "Messaggio inviato con successo!",
      
      //homepage
      keyFeatures: "Caratteristiche Principali",
      accurateInterpretation: "Interpretazione Accurata",
      accurateInterpretationDesc: "Ottieni interpretazioni affidabili basate sulle più recenti linee guida mediche.",
      userFriendly: "Facile da Usare",
      userFriendlyDesc: "Interfaccia semplice e intuitiva per una facile navigazione e inserimento dei dati.",
      trendAnalysis: "Analisi delle Tendenze",
      trendAnalysisDesc: "Tieni traccia dei cambiamenti nei risultati degli esami del sangue nel tempo.",
      rendAnalysisDesc: "Tieni traccia dei cambiamenti nei risultati dei tuoi esami del sangue nel tempo.",
      dataSecurity: "Sicurezza dei Dati",
      dataSecurityDesc: "I tuoi dati sanitari sono crittografati e archiviati in modo sicuro.",
      howItWorks: "Come Funziona",
      creditSystem: "Sistema di Crediti",
      howCreditsWork: "Come Funzionano i Crediti",
      buyCredits: "Acquista Crediti",
      credits: "Crediti",
      buyNow: "Acquista Ora",
      readyToStart: "Pronto per Iniziare a Interpretare i Tuoi Esami del Sangue?",
      signUp: "Registrati",
      improvedHomeDescription: "L'Interprete di Analisi del Sangue ti aiuta a comprendere i risultati di laboratorio, monitorare la tua salute nel tempo e ottenere preziose informazioni. Il nostro strumento basato sull'IA fornisce spiegazioni facili da capire e informazioni sanitarie personalizzate.",
      medicalGuidance: "Guida Medica",
      medicalGuidanceDesc: "Ricevi approfondimenti sulla salute e suggerimenti personalizzati basati sui risultati dei tuoi esami del sangue.",
      improvedStep1: "Inserisci i risultati dei tuoi esami del sangue manualmente o carica una foto del tuo referto di laboratorio.",
      improvedStep2: "La nostra IA analizza i tuoi risultati e fornisce un'interpretazione facile da comprendere.",
      improvedStep3: "Ottieni approfondimenti sulla salute personalizzati e analisi dei trend basati sui tuoi dati storici.",
      improvedStep4: "Utilizza la funzione di chat IA per fare domande sui tuoi risultati e ricevere indicazioni.",
      improvedStep5: "Genera rapporti completi per i tuoi archivi personali o da condividere con il tuo medico.",
      disclaimerTitle: "Avviso",
      disclaimerContent: "L'Interprete di Analisi del Sangue è uno strumento educativo progettato per aiutarti a comprendere i risultati di laboratorio e la sua connessione con il tuo storico con l'ausilio of the AI. Non fornisce diagnosi mediche e non deve essere utilizzato come sostituto di consulenza, diagnosi o trattamento medico professionale. Consulta sempre un operatore sanitario qualificato per una guida medica.",
      creditSystemDesc: "Usa i crediti per eseguire varie azioni nell'app",
      whatYouCanDo: "Cosa puoi fare con i crediti:",
      creditUsage3: "4 crediti: Aggiungere, aggiornare o eliminare esami del sangue, storia del paziente e misurazioni corporee",
      creditUsage2: "5 crediti: Scaricare report e utilizzare l'IA HealthInsight",
      creditUsage4: "1,5 crediti: Chattare con l'IA riguardo al tuo report",
      creditUsage1: "5 crediti: Utilizzare l'OCR per caricare automaticamente i risultati dei test da una foto",
      improvedCreditsExplanation: "Il nostro sistema di crediti ti permette di eseguire varie azioni nell'app. Ecco come puoi utilizzare i tuoi crediti:",
      creditPackages: "Pacchetti di Crediti",
      improvedStartNowDescription: "Registrati ora per iniziare a interpretare i tuoi esami del sangue, monitorare la tua salute e ottenere preziose informazioni. Il tuo percorso verso una migliore comprensione della salute inizia qui!",
  
      //credits
      tierUpgraded: "Il tuo livello è stato aggiornato a",
      tier: "Livello",
      unlimitedCredits: "Crediti Illimitati",
      credits: "Crediti",
      buyCredits: "Acquista Crediti",
      notEnoughCredits: "Crediti insufficienti",
      savedSuccessfully: "Salvato con successo",
      errorSaving: "Errore durante il salvataggio dei dati",
      reportDownloaded: "Report scaricato con successo",
      errorDownloading: "Errore durante il download del report",
      purchaseCredits: "Acquista Crediti",
      buy: "Compra",
      creditsFor: "crediti per",
      
      //report patient history
      generatePatientHistoryReport: "Genera Rapporto Storia del Paziente",
      reportGenerated: "Rapporto Storia del Paziente generato con successo",
      patientHistoryReport: "Rapporto Storia del Paziente",
      bodyMeasurements: "Misure Corporee",
      noBodyMeasurementsAvailable : "Nessuna misura corporea disponibile",
      noPatientHistoryAvailable: "Nessuna storia del paziente disponibile",
      examNotes: "Note Esame",
      // report for blood history
      bloodTestType: "Tipo di Analisi del Sangue",
      values: "Valori",
      editReport: "Modifica Rapporto",
      downloadReport: "Scarica Rapporto",
      editReportDetails: "Modifica Dettagli Rapporto",
      fullName: "Nome Completo",
      dateOfBirth: "Data di Nascita",
      gender: "Genere",
      hospitalName: "Nome Ospedale",
      saveDetails: "Salva Dettagli",
      pleaseEditReportFirst: "Si prega di modificare i dettagli del rapporto prima di scaricare.",
      reportPreview: "Anteprima del Rapporto",
      consentToDownload: "Acconsento al download di questo rapporto e comprendo che contiene le mie informazioni sanitarie personali.",
      pleaseProvideConsent: "Si prega di fornire il consenso per scaricare il rapporto.",
      department: "Dipartimento",
      examType: "Tipo di Esame",
      examDate: "Data dell'Esame",
      bloodTestReport: "Referto Esami del Sangue",
      departmentOfLaboratoryMedicine: "Dipartimento di Medicina di Laboratorio",
      patientName: "Nome del Paziente",
      hospital: "Ospedale",
      notAvailable: "N/D",
      bloodTestResults: "Risultati degli Esami del Sangue",
      test: "Test",
      result: "Risultato",
      referenceRange: "Intervallo di Riferimento",
      signature: "Firma",
      noTestResults: "Nessun risultato di test disponibile",
      
      //footer
      privacyPolicy: "Politica sulla Privacy",
      termsOfService: "Termini di Servizio",
      contact: "Contatto",
      //patient history other data and templates
      date: "Data",
      entryType: "Tipo di Voce",
      bodyMeasurement: "Misure Corporee",
      fullInformation: "Informazioni Complete",
      editEntry: "Modifica Voce",
      addNewEntry: "Aggiungi Nuova Voce",
      weight: "Peso",
      height: "Altezza",
      bmi: "IMC",
      bloodPressure: "Pressione Sanguigna",
      pulse: "Pulsazioni",
      newAllergies: "Nuove Allergie",
      therapy: "Terapia",
      newIntolerances: "Nuove Intolleranze",
      assessmentAndPlan: "Valutazione e Piano",
      followUp: "Controllo Successivo",
      updateConsentLabel: "Acconsento all'aggiornamento di queste informazioni",
      deleteConsentLabel: "Acconsento all'eliminazione di questa voce",
      weightPlaceholder: "Inserisci il peso in kg",
      heightPlaceholder: "Inserisci l'altezza in cm",
      bmiPlaceholder: "Inserisci l'IMC",
      bloodPressurePlaceholder: "Inserisci la pressione sanguigna (es. 120/80)",
      pulsePlaceholder: "Inserisci la frequenza del polso",
      noDataAvailable: "Nessun dato disponibile per l'analisi",
      fetchDataError: "Errore nel recupero dei dati. Si prega di riprovare.",
      analyzeDataError: "Errore nell'analisi dei dati. Si prega di riprovare.",
      //medical template
      newTranslationKey: "Nuovo testo italiano",
      medicalTemplateTitle: "Modello di Storia Clinica",
      visitOutcomeNote: "Note sull'Esito della Visita",
      historyOfPresentIllness: "Storia della Malattia Attuale",
      pastMedicalHistory: "Storia Medica Passata",
      medications: "Farmaci",
      allergies: "Allergie",
      familyHistory: "Storia Familiare",
      socialHistory: "Storia Sociale",
      reviewOfSystems: "Revisione dei Sistemi",
      physicalExamination: "Esame Fisico",
      assessment: "Valutazione",
      plan: "Piano",
      // General
      welcome: "Benvenuto su Med Web Reports",
      startInterpreting: "Inizia l'Interpretazione",
      home: "Home",
      bloodTestInput: "Inserimento Esami del Sangue",
      userDashboard: "Dashboard dell'utente",
      patientHistory: "Storia del Paziente",
      bloodTestHistory: "Storia delle Analisi del Sangue",
  
      // Home
      homeDescription: "Comprendi i risultati dei tuoi esami del sangue con facilità e precisione.",
  
      // TestInput
      inputResults: "Inserisci i Risultati delle Analisi del Sangue",
      editResults: "Modifica i Risultati delle Analisi del Sangue",
      testDate: "Data del Test",
      searchTests: "Cerca Test",
      testName: "Nome del Test",
      value: "Valore",
      unit: "Unità",
      loadMore: "Carica Altri",
      imageUploadOcr: "Caricamento Immagine e OCR",
      performOCR: "Esegui OCR",
      processingImage: "Elaborazione immagine...",
      ocrCompleted: "OCR completato con successo. Si prega di verificare i valori estratti.",
      consent: "Acconsento al salvataggio e al trattamento dei miei dati delle analisi del sangue in conformità con la politica sulla privacy.",
      saveBloodTest: "Salva Analisi del Sangue",
      updateBloodTest: "Aggiorna Analisi del Sangue",
      interpretResults: "Interpreta Risultati",
      enterValidDate: "Inserisci una data valida per l'analisi del sangue.",
      enterAtLeastOne: "Inserisci almeno un risultato valido del test.",
      invalidValue: "Valore non valido per {test}. Inserisci un numero valido.",
      dataSaved: "Dati dell'analisi del sangue salvati con successo.",
      addCustomTest: "Aggiungi Test Personalizzato",
      editCustomTest: "Modifica Test Personalizzato",
      deleteCustomTest: "Elimina Test Personalizzato",
      testName: "Nome del Test",
      value: "Valore",
      unit: "Unità",
      add: "Aggiungi",
      update: "Aggiorna",
      cancel: "Annulla",
      customTestUpdated: "Test personalizzato aggiornato con successo",
      customTestDeleted: "Test personalizzato eliminato con successo",
      confirmDeleteCustomTest: "Sei sicuro di voler eliminare questo test personalizzato?",
  
      // ImageUpload
      uploadTestResultImage: "Carica Immagine del Risultato del Test",
      uploadImage: "Carica Immagine",
      uploadValidImage: "Carica un file immagine valido.",
  
      // Results
      bloodTestResultsInterpretation: "Interpretazione dei Risultati delle Analisi del Sangue",
      enterNewResults: "Inserisci Nuovi Risultati",
      printResults: "Stampa Risultati",
      summary: "Riepilogo",
      summaryNote: "Nota: Questo riepilogo si basa solo sui risultati del test che hai fornito e non deve essere considerato come un consiglio medico completo. Consulta sempre un professionista sanitario per una corretta interpretazione dei risultati del test e per una guida medica.",
  
      // Authentication
      login: "Accedi",
      register: "Registrati",
      email: "Indirizzo Email",
      password: "Password",
      confirmPassword: "Conferma Password",
      gdprConsent: "Acconsento al trattamento dei miei dati personali in conformità con il GDPR",
      logout: "Esci",
      pleaseLogin: "Effettua il login per accedere alla cronologia delle analisi del sangue.",
      loginFailed: "Accesso fallito. Controlla le tue credenziali.",
      registrationSuccessful: "Registrazione riuscita! Reindirizzamento al login...",
      registrationFailed: "Registrazione fallita. ",
      passwordsMismatch: "Le password non corrispondono.",
  
      // PatientDataOverview
      patientDataOverview: "Panoramica dei Dati del Paziente",
      viewBloodTestHistory: "Visualizza Storia delle Analisi del Sangue",
      viewFullPatientHistory: "Visualizza Storia Completa del Paziente",
  
      // PatientHistory
      editPatientHistory: "Modifica Storia del Paziente",
      information: "Informazioni",
      actions: "Azioni",
      update: "Aggiorna",
      cancel: "Annulla",
      delete: "Elimina",
      confirmDeletion: "Conferma Eliminazione",
      deleteConfirmation: "Sei sicuro di voler eliminare questa voce della storia? Questa azione non può essere annullata.",
      noHistoryFound: "Nessuna voce della storia trovata.",
  
      // Errors and Notifications
      error: "Errore",
      success: "Successo",
      pleaseConsent: "Fornisci il consenso per memorizzare ed elaborare i tuoi dati.",
      mustBeLoggedIn: "Devi essere loggato per salvare i dati delle analisi del sangue.",
      errorProcessingImage: "Errore nell'elaborazione dell'immagine. Riprova o inserisci i valori manualmente.",
      errorFetchingHistory: "Errore nel recupero della storia del paziente. Riprova più tardi.",
      errorSavingData: "Errore nel salvare l'analisi del sangue: {error}",
      updateSuccess: "Storia del paziente aggiornata con successo.",
      updateError: "Impossibile aggiornare la storia del paziente. Si prega di riprovare.",
      save: "Salva",
  
      // HealthcareDisclaimer
      disclaimer: "Disclaimer: Questa app è solo a scopo informativo e non fornisce consulenza medica. I risultati e le interpretazioni fornite non devono essere considerati come un sostituto di consulenza medica professionale, diagnosi o trattamento. Consulta sempre il tuo medico o altro operatore sanitario qualificato per qualsiasi domanda tu possa avere riguardo una condizione medica.",
  
      // DataRetentionControls
      dataControl: "Controllo dei Dati",
      deleteDataRight: "Hai il diritto di richiedere la cancellazione dei tuoi dati in qualsiasi momento.",
      deleteMyData: "Elimina i Miei Dati",
  
      // GDPRConsent
      consentForDataProcessing: "Consenso al Trattamento dei Dati",
      gdprExplanation: "Raccogliamo ed elaboriamo i tuoi dati sanitari per fornire servizi di interpretazione delle analisi del sangue. I tuoi dati sono crittografati e archiviati in modo sicuro. Hai il diritto di accedere, rettificare o cancellare i tuoi dati in qualsiasi momento. Acconsenti a questo trattamento dei dati?",
      decline: "Rifiuta",
      iConsent: "Acconsento",

      //BloodTestHistory
      noBloodTestsFound: "Nessun test del sangue trovato. Aggiungi alcuni test.",
      searchTests: "Cerca Test",
      editBloodTest: "Modifica Test del Sangue",
      editBloodTestInstructions: "Modifica i dettagli del test del sangue qui sotto.",
      deleteBloodTestConfirmation: "Sei sicuro di voler eliminare questo test del sangue? Questa azione non può essere annullata.",
      deleteBloodTestConsent: "Acconsento all'eliminazione di questa voce del test del sangue.",
      updateBloodTestConsent: "Acconsento all'aggiornamento e al trattamento dei miei dati del test del sangue in conformità con la politica sulla privacy.",

      // Health Insights (New)
      healthInsights: "Approfondimenti sulla Salute",
      analyzeHealth: "Analizza la Mia Salute",
      healthAnalysis: "Analisi della Salute",
      chatWithAI: "Chatta con il report AI",
      chatCreditCost: "Ogni interazione con la chat costa {credits} crediti",
      notEnoughCreditsChat: "Crediti insufficienti per la chat. Ogni interazione costa 1,5 crediti.",
      chatCreditsDeducted: "{credits} crediti detratti per l'interazione con la chat.",
      chatError: "Si è verificato un errore durante la chat. Si prega di riprovare.",
      askAboutHealth: "Chiedi della tua salute...",
      send: "Invia",
      healthInsightsReport: "Rapporto sugli Approfondimenti sulla Salute",
      analysisDate: "Data dell'Analisi",
      printReport: "Stampa Rapporto",
      noAnalysisToReport: "Nessuna analisi disponibile per generare un rapporto. Si prega di analizzare prima la propria salute.",
      user: "Utente",
      ai: "IA",
      chatHistory: "Cronologia della Chat",
      consentForm: "Modulo di Consenso",
      consentFormText: "Con la presente confermo di aver letto e compreso gli approfondimenti sulla salute forniti in questo rapporto. Riconosco che questo rapporto è solo a scopo informativo e non costituisce un consiglio medico. Accetto di consultare un professionista sanitario qualificato prima di prendere qualsiasi decisione basata su queste informazioni.",
      patientSignature: "Firma del Paziente",

      generateReport: "Genera Rapporto",
      patientDetails: "Dettagli del Paziente",
      next: "Avanti",
      back: "Indietro",
      consentRequired: "Consenso Richiesto",
      consentText: "Generando questo rapporto, riconosci che le informazioni fornite sono solo a scopo informativo e non costituiscono un consiglio medico. Si prega di consultare un professionista sanitario per una corretta interpretazione e guida medica.",
      consentCheckboxLabel: "Ho letto e accetto i termini sopra indicati",
      pleaseProvideDetails: "Si prega di fornire i seguenti dettagli per generare il rapporto:",
      noDataForQuestions: "Nessun dato del paziente disponibile per generare domande. Assicurati che i dettagli del paziente e gli esami del sangue siano disponibili.",
      errorGeneratingQuestions: "Errore nella generazione delle domande suggerite. Per favore riprova.",
      errorFetchingData: "Errore nel recupero dei dati del paziente. Per favore riprova.",
      importantQuestionsForDoctor: "Domande Consigliate da Fare al Tuo Medico",

      patientHistory: "Storia del Paziente",
      medicalHistory: "Storia Medica",
      visitOutcomeNote: "Note sull'Esito della Visita",
      therapy: "Terapia",
      consentLabel: "Acconsento all'aggiornamento di queste informazioni mediche",

      getStartedFree: "Inizia Gratis",
      whyRegister: "Perché Registrarsi?",
      registerBenefit1: "Accesso gratuito all'interpretazione dei test del sangue di base",
      registerBenefit2: "Archiviazione sicura dei tuoi dati sanitari",
      registerBenefit3: "Monitora le tue tendenze di salute nel tempo",
      registerBenefit4: "Accesso a intuizioni sulla salute basate sull'intelligenza artificiale",
      userTestimonials: "Cosa Dicono i Nostri Utenti",
      testimonial1: "Questa app ha reso molto più facile capire i miei test del sangue. È come avere un assistente sanitario personale!",
      testimonial2: "Mi piace come posso monitorare i miei progressi di salute nel tempo. È motivante vedere i miglioramenti.",
      testimonial3: "Le spiegazioni sono chiare e facili da capire. Mi ha aiutato a parlare meglio con il mio medico.",
      callToActionDescription: "Unisciti a migliaia di utenti che stanno prendendo il controllo della loro salute. Iscriviti ora e inizia a capire i tuoi test del sangue come mai prima d'ora!",
      signUpFree: "Iscriviti Gratis",
      freeCreditsOffer: "Iscriviti ora e ottieni 20 crediti gratis!",
      freeCredits: "Crediti Gratuiti",
      freeCreditsDesc: "Inizia con 20 crediti gratuiti per esplorare tutte le funzionalità della nostra piattaforma.",
      registerBenefit5: "Ottieni 20 crediti gratuiti per iniziare subito a interpretare i tuoi test del sangue.",

      invalidFileType: "Tipo di file non valido. Carica un file .png, .jpeg, .jpg o .webp.",
      fileTooLarge: "Il file è troppo grande. Carica un'immagine più piccola di 2MB.",
      uploadImage: "Carica immagine",

      //comparison premium
      compareFeaturesComp: "Confronta le Funzionalità",
      freeVersionComp: "Versione Gratuita",
      premiumVersionComp: "Versione Premium",
      ocrFeatureComp: "Tecnologia OCR",
      ocrDescriptionComp: "Carica automaticamente i campi con le foto",
      creditsComp: "Crediti",
      creditsDescriptionComp: "Crediti per l'utilizzo delle funzionalità dell'app",
      moreCreditsTiersComp: "Più crediti in base al livello",
      addModifyDeleteComp: "Aggiungi, Modifica, Elimina Dati",
      addModifyDeleteDescriptionComp: "Gestisci esami del sangue, storia del paziente e misurazioni corporee",
      aiInsightsComp: "Approfondimenti basati su IA",
      aiInsightsDescriptionComp: "Ottieni approfondimenti sulla salute generati dall'IA",

      //validation login
      tooManyAttempts: "Troppi tentativi falliti. Per favore riprova più tardi.",
      accountLocked: "Il tuo account è temporaneamente bloccato. Riprova tra 15 minuti.",
      weakPassword: "La password è troppo debole. Deve essere lunga almeno 8 caratteri e includere maiuscole, minuscole, numeri e caratteri speciali.",
      passwordRequirements: "La password deve essere lunga almeno 8 caratteri e includere maiuscole, minuscole, numeri e caratteri speciali.",
      invalidEmail: "Inserisci un indirizzo email valido.",

      //faq
      faqTitle: "Domande Frequenti",
      faqBloodTestQuestion: "Come aggiungo i risultati dei miei esami del sangue?",
      faqBloodTestStep1: "Clicca su 'Inserimento Analisi del Sangue' nel pannello di navigazione.",
      faqBloodTestStep2: "Inserisci i campi obbligatori: Data del Test e almeno 1 valore dell'esame del sangue e l'Unità.",
      faqBloodTestStep3: "Clicca sulla casella di consenso per accettare l'archiviazione e l'elaborazione dei dati in conformità con la politica sulla privacy.",
      faqBloodTestStep4: "Clicca su 'Salva Analisi del Sangue' per salvare i dati e archiviarli.",
      faqPatientHistoryQuestion: "Come aggiungo la mia Storia Clinica?",
      faqPatientHistoryStep1: "Clicca su 'Storia del Paziente' nel menu di navigazione.",
      faqPatientHistoryStep2: "Clicca su 'Aggiungi Storia del Paziente' per iniziare a inserire i tuoi dati.",
      faqPatientHistoryStep3: "Scegli la sezione corretta in base ai dati del paziente che desideri inserire: Storia del Paziente o Misurazioni Corporee.",
      faqPatientHistoryStep4: "Compila i campi appropriati per la sezione scelta.",
      faqPatientHistoryStep5: "Fornisci il consenso nella casella di controllo e clicca su 'Salva' per archiviare i dati.",
      faqAIAnalysisQuestion: "Come eseguo l'analisi AI sui miei dati?",
      faqAIAnalysisStep1: "Clicca su 'Dashboard Utente'.",
      faqAIAnalysisStep2: "Clicca su 'Analizza la mia Salute'.",
      faqAIAnalysisStep3: "Verifica la correttezza dei tuoi dati e scorri verso il basso.",
      faqAIAnalysisStep4: "Clicca su 'Analizza la mia Salute' per avviare l'analisi AI.",
      faqLanguageQuestion: "Come cambio tra inglese e italiano?",
      faqLanguageAnswer: "Nella barra di navigazione, clicca su 'IT' per passare all'italiano e 'EN' per passare all'inglese.",
      faqBuyCreditsQuestion: "Come acquisto i crediti?",
      faqBuyCreditsStep1: "Nella barra di navigazione, clicca su 'Acquista Crediti'.",
      faqBuyCreditsStep2: "Scegli il livello che preferisci e clicca su 'Acquista', quindi segui il processo di pagamento di Stripe.",
      faqBuyCreditsStep3: "Clicca su 'Contattaci' se desideri un accordo diverso.",

      analyzing: "caricamento",
      addBloodTest: "Aggiungi Esame del Sangue",
      invalidBloodTestData: "Dato invalido.",
      userNotLoggedIn: "L'utente non ha effettuato l'accesso.",
      incorrectPassword: "La password attuale non è corretta.",
      reauthenticatePrompt: "Inserisci la tua password attuale per aggiornare il tuo profilo.",
      errorUpdatingProfile: "Errore durante l'aggiornamento del profilo.",
      currentPassword: "Password attuale",
      newPassword: "Nuova password",
      enterCurrentPassword: "Inserisci la tua password attuale per modificare l'email o la password.",
      leaveBlankToKeepSame: "Lascia vuoto per mantenere la stessa password.",
      saveChanges: "Salva modifiche",
      premiumExplanation: 'è considerato attualmente premium user chiunque abbia comprato crediti almeno una volta. Questo potrebbe cambiare nel prossimo futuro',
      firstName:"Nome",
      lastName:"Cognome",
      infoResults: "Per ottenere l'interpretazione con l intelligenza artificiale vai alla pagina: Dashboard dell'utente/ Approfondimento della salute"
    }

  };